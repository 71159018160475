import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ShapeBlog from "../components/shapes/ShapeBlog"

const pageReference = ({ pageContext }) => {
  const {
    title,
    referenceLogo,
    authorImage,
    authorName,
    authorPosition,
    referenceExcerpt,
    referenceContent,
    referenceSidebarInfo,
    referenceSidebarPageUrl,
    referenceSidebarPageAddress,
  } = pageContext.data
  return (
    <Layout>
      <SEO title={title} />
      <main className="reference_page">
        <ShapeBlog />

        <section className="page_header_wrapper">
          <img className="img-fluid" src={referenceLogo} alt={title} />
        </section>
        <section className="container-fluid">
          <div className="row">
            <div className="col-lg-3">
              <div className="side_inner">
                {referenceSidebarInfo.map((node, index) => (
                  <div key={index}>
                    <strong>{node.reference_sidebar_info_label}</strong>
                    <p>{node.reference_sidebar_info_content}</p>
                  </div>
                ))}
                <div>
                  <strong>Strona internetowa:</strong>
                  <p>
                    <a
                      href={referenceSidebarPageUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {referenceSidebarPageAddress}
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-8 offset-lg-1">
              <div
                className="page_header"
                dangerouslySetInnerHTML={{ __html: referenceContent }}
              />
              <div className="inner_testimonial">
                <div className="thumb">
                  <img
                    className="img-fluid"
                    src={authorImage}
                    alt={authorName}
                  />
                </div>
                <div className="inner_testimonial__text" dangerouslySetInnerHTML={{ __html: referenceExcerpt }} />
                <span className="testimonial_name">
                  {authorName} <br /> {authorPosition}
                </span>
                <span className="testimonial_company">{title}</span>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default pageReference
